// Copyright 2018-2021 NXGN Management, LLC. All Rights Reserved.

$mf-large-illustrations__file-location: '~mf-react-standards/src/img/large-illustrations' !default;
$mf-spot-illustrations__file-location: '~mf-react-standards/src/img/spot' !default;
$mf-logos__file-location: '~mf-react-standards/src/img/logos' !default;

@import '~mf-react-standards/src/scss/variables';

//overwrites to use mf-react-standards folders
$mf-font__file-location: '~mf-react-standards/src/fonts';
$mf-img__file-location: '~mf-react-standards/src/img';
$mf-images__file-location: '~mf-react-standards/src/img';

@import '~mf-react-standards/src/scss/principles/mf-typography'; // This MUST be outside of the .medfusion namespace

.medfusion {
	@import '~mf-react-standards/src/scss/bootstrap';
	@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';
	@import '~mf-react-standards/src/scss/util/bs-xl-grid';

	@import '~mf-react-standards/src/scss/util';
	@import '~mf-react-standards/src/scss/basics';
	@import '~mf-react-standards/src/scss/components';
	@import '~mf-react-standards/src/scss/templates';
}
