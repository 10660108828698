// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

@import 'mf-react-standards.scss';

// utilities - spacing

@mixin breakpoint($point) {
	@if $point == desktop {
		@media (max-width: 1440px) {
			@content;
		}
	} @else if $point == laptop {
		@media (max-width: 1024px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 768px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 540px) {
			@content;
		}
	} @else if $point == mobileonly {
		@media (min-width: 320px) {
			@content;
		}
	}
}

$spacer: 1rem;
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: ($spacer * 0.25) !important;
}

.mt-2 {
	margin-top: ($spacer * 0.5) !important;
}

.mt-3 {
	margin-top: $spacer !important;
}

.mt-4 {
	margin-top: $spacer * 1.5 !important;
}

.mt-5 {
	margin-top: $spacer * 3 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: ($spacer * 0.25) !important;
}

.mb-2 {
	margin-bottom: ($spacer * 0.5) !important;
}

.mb-3 {
	margin-bottom: $spacer !important;
}

.mb-4 {
	margin-bottom: $spacer * 1.5 !important;
}

.mb-5 {
	margin-bottom: $spacer * 3 !important;
}

.m-3 {
	margin: $spacer !important;
}

.my-3 {
	margin-bottom: $spacer !important;
	margin-top: $spacer !important;
}

.m-0 {
	margin: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

// font sizes
.fs-small {
	font-size: 0.75rem !important;
}

form input[type='submit'] {
	margin-top: $spacer * 0.5;
}

form {
	#dob-Year {
		color: black;
		max-width: 60px;
		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

.loading-button-full {
	@extend .mf-loading;
}

.loading-button-168 {
	@extend .mf-loading;
	min-width: 168px;
	width: unset !important;
	float: right;
	@include breakpoint(mobile) {
		width: 100% !important;
	}
}

.loading-order-1 {
	order: 1;
	@include breakpoint(mobile) {
		order: unset;
	}
}

.loading-order-2 {
	order: 2;
	@include breakpoint(mobile) {
		order: unset;
	}
}

.primary-button {
	@extend .mf-cta__primary;
	&:not(.no-padding) {
		padding: 0 24px !important;
		width: max-content;
		min-width: 168px;
		float: right;
		@include breakpoint(mobile) {
			width: 100% !important;
		}
	}
}

.secondary-button {
	@extend .mf-cta__secondary;
	&:not(.no-padding) {
		width: max-content;
		padding: 0 24px !important;
		@include breakpoint(mobile) {
			width: 100% !important;
		}
	}
}

.padding-12 {
	padding-right: 12px;
	padding-left: 12px;
}

.precheck {
	&-[class^='col-'] {
		@extend [class^='col-'];
		@extend .padding-12;
	}
	&-container {
		@extend .container;
		@extend .padding-12;
		overflow-x: clip;
	}
	&-row {
		@extend .row;
	}
	&-col-md-offset-1 {
		@extend .col-md-offset-1;
	}
	&-col-md-offset-3 {
		@extend .col-md-offset-3;
		@extend .padding-12;
	}
	&-col-lg-offset-1 {
		@extend .col-lg-offset-1;
	}
	&-col-lg-offset-2 {
		@extend .col-lg-offset-2;
	}
	&-col-lg-offset-4 {
		@extend .col-lg-offset-4;
	}
	&-col-lg-4 {
		@extend .col-lg-4;
		@extend .padding-12;
	}
	&-col-lg-3 {
		@extend .col-lg-3;
		@extend .padding-12;
	}
	&-col-lg-6 {
		@extend .col-lg-6;
		@extend .padding-12;
	}
	&-col-sm-8 {
		@extend .col-sm-8;
		@extend .padding-12;
	}
	&-col-sm-offset-2 {
		@extend .col-sm-offset-2;
	}
	&-col-md-4 {
		@extend .col-md-4;
		@extend .padding-12;
	}
	&-col-md-6 {
		@extend .col-md-6;
		@extend .padding-12;
	}
	&-col-md-12 {
		@extend .col-md-12;
		@extend .padding-12;
	}
	&-col-md-offset-0 {
		@extend .col-md-offset-0;
	}
	&-modal-lg {
		@extend .modal-lg;
	}
	&-modal {
		@extend .modal;
	}
	&-in {
		@extend .in;
	}
	&-modal-backdrop {
		@extend .modal-backdrop;
	}
	&-fade {
		@extend .fade;
	}
	&-modal-dialog {
		@extend .modal-dialog;
	}
	&-modal-content {
		@extend .modal-content;
	}
	&-form-control{
		@extend .form-control;
	}
}
